import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import { FetchRequest } from '@rails/request.js'
window.Turbo = Turbo

export default class extends Controller {
  static values = { url: String }

  connect() {
  }

  showMaterialRelease(event) {
    Turbo.visit(this.urlValue)
  }

  async toggleArchived(event){
    const checkbox = event.target;
    const isChecked = checkbox.checked;
    const action = isChecked ? "Archive and Lock" : "Reopen and Unlock";
    if (confirm(`Are you sure you want to ${action} this item?`)) {
      // User canceled, so revert the checkbox state
      const request = new FetchRequest("put", this.urlValue, { responseKind: "turbo-stream" })
      await request.perform()
    }
    else{
      checkbox.checked = !isChecked;
    }
  }
}
