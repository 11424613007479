import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'sortField', 'disabledFields' ];

  connect() {
    if(!this.sortFieldTarget.value){
      this.disabledFieldsTargets.forEach((field) => {
        field.querySelector("input").checked = false;
        field.querySelector("input").disabled = true;
      });
    }
  }

  disconnect() {
  }

  updateFields() {
    if(this.sortFieldTarget.value){
      this.disabledFieldsTargets.forEach((field) => {
        field.querySelector("input").disabled = false;
      });
    } else {
      this.disabledFieldsTargets.forEach((field) => {
        field.querySelector("input").checked = false;
        field.querySelector("input").disabled = true;
      });
    }
    
  }
}